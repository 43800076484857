// https://confluence.interfax.ru/pages/viewpage.action?pageId=79823516
// swagger: /permissions/
export const ACCESS = Object.freeze({
  ADMIN: {
    SUPERUSER: "1.1",
    USER_MGT: "1.2",
    USER_ACCESS_MGT: "1.3",
    NEWS_ROUTES_MGT: "1.4",
  },
  NEWS: {
    CREATION: "2.1",
    IN_WORK: "2.2",
    INCOMING: "2.3",
    USER: "2.4",
    PUBLISHING: "2.5",
    GROUPMATES_NEWS: "2.6",
    PUBLISHED: "2.7",
    EMBARGO: "2.8",
    EMBARGO_MGT: "2.9",
    REPUBLISH: "2.10",
    REVOKE: "2.11",
    RESTORE_REVOKED: "2.20",
    DUMP: "2.12",
    DUMP_SEND: "2.13",
    DUMP_EXTRACT: "2.14",
    SEARCH: "2.15",
    EXTENSION: "2.16",
    ORIGINALS: "2.17",
    FLASH_SEND: "2.18",
    CANVAS: "2.19",
  },
  LEADS: {
    SEND_WRK: "3.1",
    TAKE_WRK: "3.2",
  },
  INFOBREAKS: {
    LIST: "3.3",
    TAKE_WRK: "3.4",
    REJECT: "3.5",
  },
  ASOI: {
    INCOMING: "4.1",
    OUTGOING: "4.2",
    EMBARGO: "4.3",
  },
  ENTITIES: {
    MGT: "5.1",
  },
  AGENCY: {
    TASS: "6.1",
    RIA: "6.2",
    BNS: "6.3",
  }
})
