<script setup lang="ts">
import NewsInProgressListItem from "./NewsInProgressListItem.vue"
import PanelList from "@/ContextTab/components/List/PanelList.vue"
import useTaskPanelList from "@/ContextTab/modules/news/tasks-panel/composables/useTaskPanelList"
import type { DateMode } from "@/ContextTab/components/Date/types"

withDefaults(
  defineProps<{
    dateMode: DateMode
    isLegacyRoute: boolean
  }>(),
  {}
)

const emit = defineEmits<{
  (e: "open", id: number): void
  (e: "dateModeChanged", value: DateMode): void
}>()

const {
  items,
  selectedId,
  selectItem,
  onReachedEnd,
  hasMoreItems,
  isFetching,
  errors,
} = useTaskPanelList("inProgress")
</script>

<template>
  <PanelList
    :items="items"
    :selectedItemId="selectedId"
    :hasMoreItems="hasMoreItems"
    :errors="errors"
    :isFetching="isFetching"
    :initialSize="124"
    dateDelimeterColor="#f3e4ca"
    @select="selectItem"
    @reachedEnd="onReachedEnd"
    @mouseleave="() => selectItem()"
  >
    <template #default="{ index }">
      <NewsInProgressListItem
        :item="items[index]"
        :isSelected="items[index]?.id === selectedId"
        :dateMode="dateMode"
        :isLegacyRoute="isLegacyRoute"
        @dateModeChanged="$emit('dateModeChanged', $event)"
        @open="emit('open', $event)"
        @mouseenter="() => selectItem(items[index]?.id)"
      />
    </template>
  </PanelList>
</template>

<style lang="postcss" scoped></style>
